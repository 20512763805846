import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/pricing.css'

const Pricing = () => (
  <Layout>
    <SEO title="Fitness and Spirit Services" />
    <div className="container-services">
      <div>
        <h1>Pricing</h1>
        <div className="price-table-wrapper">
          <div className="pricing-table">
            <h2 className="pricing-table__header">- MEDICAL STARTER -</h2>
            <h3 className="pricing-table__price">3 Weeks / $675 </h3>
            <Link to="/contact" className="pricing-table__button">
              Join Now!
            </Link>
            <ul className="pricing-table__list">
              <li>
                6 Accountability
                <br />
                Check-ins
              </li>
              <li>Virtual or At-Home Training</li>
              <li>Individualized & Protocol Based Programming</li>
            </ul>
          </div>
          <div className="pricing-table">
            <h2 className="pricing-table__header">
              - MEDICAL MEMBERSHIP (A) -
            </h2>
            <h3 className="pricing-table__price">6 Weeks / $1260</h3>
            <Link to="/contact" className="pricing-table__button">
              Join Now!
            </Link>
            <ul className="pricing-table__list">
              <li>
                12 Accountability
                <br />
                Check-ins
              </li>
              <li>Virtual or At-Home Training</li>
              <li>Individualized & Protocol Based Programming</li>
            </ul>
          </div>
          <div className="pricing-table">
            <h2 className="pricing-table__header">
              - MEDICAL MEMBERSHIP (B) -
            </h2>
            <h3 className="pricing-table__price">9 Weeks / $1755</h3>
            <Link to="/contact" className="pricing-table__button">
              Join Now!
            </Link>
            <ul className="pricing-table__list">
              <li>
                18 Accountability
                <br />
                Check-ins
              </li>
              <li>Virtual or At-Home Training</li>
              <li>Individualized & Protocol Based Programming</li>
            </ul>
          </div>
          <div className="pricing-table">
            <h2 className="pricing-table__header">
              - MEDICAL MEMBERSHIP (C) -
            </h2>
            <h3 className="pricing-table__price">12 Weeks / $2160</h3>
            <Link to="/contact" className="pricing-table__button">
              Join Now!
            </Link>
            <ul className="pricing-table__list">
              <li>
                24 Accountability
                <br />
                Check-ins
              </li>
              <li>Virtual or At-Home Training</li>
              <li>Individualized & Protocol Based Programming</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-services">
        <h2>Frequently Asked Questions</h2>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is the difference between in-person and virtual?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The main difference between in-person and virtual is there is a
                flat rate of $50 per hour of exercise training. Virtual will be
                held through Zoom, and I will be present with you throughout
                your whole training session. I will still provide modifications
                as necessary, however, I will be unable to physically manipulate
                you. Each session will end with guided stretching and
                relaxation, however, I will be unable to provide acupressure,
                massage, or/and use of my Recover Fun Gun for recovery and
                healing.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is an accountability check-in?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                An accountability check-in will be a short text or phone call to
                assure you are on the right track agreed upon, to accomplish
                your healthiest version of self!
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>What is a protocol?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                As a Medical Exercise Specialist, you will be given a thorough
                medical history followed by a “musculoskeletal screening.” Based
                on your results, combined with further assessments and goals, I
                will design a protocol-based exercise program specific to your
                medical needs. These protocols are a new standard containing
                specific staged exercises and functional outcome measures for
                the delivery of your exercise program. I will not use any
                physical therapy modalities. My sole modality used is exercise.
                In addition, lifestyle management training will provide you with
                education, defined wellness strategies and independent exercise
                guidelines to create functional ease.
              </p>
              <blockquote className="text-left text-base">
                Exercise is the key to long-term management of most medical
                conditions.
              </blockquote>
              <cite className="text-base">Michael K. Jones</cite>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What medical conditions/procedures can I provide service for?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                All, but not limited to the following… Hormone Imbalances, Gut
                Health, Adrenal Fatigue Syndrome, Fibromyalgia, Chronic Pain,
                Arthritis, Osteoarthritis, Lumbar Disc Herniation, Laminectomy,
                Spondylothesis, Low Back Pain, Degenerative Joint Disease,
                Spinal Stenosis, Spinal Fusion, Parkinson’s Disease,
                Hypertension, Diabetes, Total Hip Replacement, IT Band Syndrome,
                Total Knee Replacement, Platello-Femoral Syndrome, Ankle
                Sprain/Fracture, Cervical Strain/Disc Herniation, Medial
                Epicondylitis, Lateral Epicondylitis, Carpal Tunnel Syndrome,
                Shoulder Impingement Syndrome, Shoulder Dislocation, Bicipital
                Tendonitis, AC Joint Separation, Frozen Shoulder, Total Shoulder
                Replacement, Rotator Cuff Tear, Glenoid Labrum Tear.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  </Layout>
)

export default Pricing
